import { MdOutlineLocationOn, MdPeopleOutline } from "react-icons/md";
import { Stack } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import NoImagePlaceholder from "../../../../assets/NoPicture.png";
import * as Styled from "./customStyles";
import { Link } from "react-router-dom";
import useScreenSize from "../../../../hooks/useScreenSize";
import API from "../../../../api";
import { useQuery } from "react-query";
import useAuthProvider from "../../../../hooks/useAuthProvider";

function SearchItem({ item, altProfileImage = "" }: any) {
  const { isDesktopOrLaptop } = useScreenSize();
  const direction = isDesktopOrLaptop ? "horizontal" : "vertical";

  const token = useAuthProvider.useHandleLoginRedirect();
  const emailAddress = item.emailaddress1;

  const profilePictureQuery = useQuery(
    [API.keys.GET_PROFILE_PICTURE, emailAddress],
    API.queries.fetchProfilePicture,
    {
      enabled: !!emailAddress && !!token,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

  let imageURL = NoImagePlaceholder;
  if (profilePictureQuery?.data) {
    const urlCreator = window.URL || window.webkitURL;
    imageURL = urlCreator.createObjectURL(profilePictureQuery?.data);
  } else if (!item?.entityimage) {
    item.entityimage = altProfileImage;
  } else if (item?.entityimage) {
    imageURL = "data:image/jpeg;base64," + item?.entityimage;
  } else {
    imageURL = NoImagePlaceholder;
  }

  return (
    <Styled.Root>
      <Link to={`/profile/${item.emailaddress1}`}>
        <Styled.Media src={imageURL} alt="This" width="120" height="120" />
      </Link>
      <Styled.CardInfo>
        <p className="title">
          <Link to={`/profile/${item.emailaddress1}`}>{item?.fullname} </Link>
        </p>
        {/* <p className="profession">{item?.jobtitle}</p> */}

        <Stack direction={direction} gap={isDesktopOrLaptop ? 5 : 0}>
          <div
            className={isDesktopOrLaptop ? "w-50 profession" : "profession"}
            style={{ minWidth: "30%" }}
          >
            {item?.jobtitle}
          </div>
          <div className={isDesktopOrLaptop ? "w-50 profession" : "profession"}>
            {item?.COE_Public_Private === "N/A" ? "" : item?.COE_Public_Private}
          </div>
        </Stack>

        <Stack direction={direction} gap={isDesktopOrLaptop ? 5 : 0}>
          <div
            className={isDesktopOrLaptop ? "w-50 location" : "location"}
            style={{ minWidth: "30%" }}
          >
            <span>
              <MdOutlineLocationOn size={16} />
            </span>
            {item?.officelocation}
          </div>
          <div className={isDesktopOrLaptop ? "w-50 location" : "location"}>
            <span>
              <MdPeopleOutline size={16} />
            </span>
            {item?.department}
          </div>
        </Stack>

        <Stack direction={direction} gap={isDesktopOrLaptop ? 5 : 0}>
          <div
            className={isDesktopOrLaptop ? "w-50 email" : "email"}
            style={{ minWidth: "30%" }}
          >
            <span>
              <AiOutlineMail />
            </span>
            {item?.emailaddress1}
          </div>
          {item.mobilephone && (
            <div className={isDesktopOrLaptop ? "w-50 location" : "location"}>
              <span>
                <FiPhone size={16} />
              </span>
              {item?.mobilephone}
            </div>
          )}
        </Stack>
      </Styled.CardInfo>
    </Styled.Root>
  );
}

export default SearchItem;
