import { MdOutlineLocationOn } from "react-icons/md";
import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Styled from "./customStyles";
import NoImagePlaceholder from "../../assets/NoPicture.png";
import API from "../../api";
import { useQuery } from "react-query";
import useAuthProvider from "../../hooks/useAuthProvider";

export const formatDisplayName = (
  displayName: string,
  searchText: string = ""
): string => {
  if (!searchText) {
    return displayName || "";
  }

  const matches = displayName.match(new RegExp(searchText, "i"));

  if (!!matches) {
    matches.forEach((match) => {
      displayName = displayName?.replace(match, `<strong>${match}</strong>`);
    });
  }

  return displayName;
};

function ProfileCardHorizontal({
  data,
  altProfileImage = "",
  small = false,
  keyword = "",
}: any) {
  const token = useAuthProvider.useHandleLoginRedirect();
  const emailAddress = data?.Work_Email;

  const profilePictureQuery = useQuery(
    [API.keys.GET_PROFILE_PICTURE, emailAddress],
    API.queries.fetchProfilePicture,
    {
      enabled: !!emailAddress && !!token,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

  let imageURL = NoImagePlaceholder;
  if (profilePictureQuery?.data) {
    const urlCreator = window.URL || window.webkitURL;
    imageURL = urlCreator.createObjectURL(profilePictureQuery?.data);
  } else if (!data?.entityimage) {
    data.entityimage = altProfileImage;
  } else if (data?.entityimage) {
    imageURL = `data:image/jpeg;base64,${data?.entityimage}`;
  } else {
    imageURL = NoImagePlaceholder;
  }

  return (
    <Styled.Root direction="horizontal">
      <Link to={`/profile/${data?.Work_Email}`}>
        <Styled.Media
          src={imageURL}
          alt="This"
          width={small ? "40" : "60"}
          height={small ? "40" : "60"}
          rounded
        />
      </Link>
      <Styled.CardInfo>
        <p className="title">
          <Link to={`/profile/${data?.Work_Email}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: formatDisplayName(
                  `${data?.First_Name} ${data?.Last_Name}`,
                  keyword
                ),
              }}
            ></div>
          </Link>
        </p>
        <p className="profession">{data?.Job_Title}</p>

        <Stack direction="horizontal" gap={5}>
          <div className="location" style={{ minWidth: "30%" }}>
            <span>
              <MdOutlineLocationOn size={16} />
            </span>
            {data?.Location}
          </div>
        </Stack>
      </Styled.CardInfo>
    </Styled.Root>
  );
}

export default ProfileCardHorizontal;
