import { useQuery } from "react-query";

import API from "../api";
import useQueryString from "./useQueryString";
import useAuthProvider from "./useAuthProvider";
import useSearchFilters from "./useFilters";

function useSearchManager(keyName = "firstname") {
  const qs = useQueryString();
  const token = useAuthProvider.useHandleLoginRedirect();
  const filter = useSearchFilters();
  const isCustomSearch =
    !!qs.get("showFullSearch")?.length ||
    !!qs.get("platform")?.length ||
    !!qs.get("division")?.length ||
    !!qs.get("team")?.length ||
    !!qs.get("location")?.length ||
    !!qs.get("Vendor")?.length ||
    !!qs.get("department")?.length;

  const encodeString = (value = "") => {
    return encodeURIComponent(value.replace(/'/g, "''"));
  };

  const generatePayload = () => {
    let payload: any = {};

    const filters: any = [
      "department",
      "officelocation",
      "team",
      "division",
      "platform",
      "Vendor",
    ];

    if (qs.get("showFullSearch")) {
      filters.push("keyword");
    }

    filters.forEach((item: any) => {
      if (qs.get(item)) payload[item] = qs.get(item);
    });

    if (!(Object.keys(payload).length > 0)) {
      payload = null;
    } else {
      let $filters: any = [];
      Object.keys(payload).forEach((key) => {
        if (key === "keyword" && qs.get("showFullSearch")) {
          return $filters.push(
            `startsWith(fields/${keyName},'${encodeString(payload[key])}')`
          );
        }
        if (key === "keyword" && qs.get("type") === "location") {
          return $filters.push(
            `startsWith(fields/officelocation,'${payload[key]}')`
          );
        }
        if (
          filters?.includes(key) &&
          (key !== "location" || qs.get("type") !== "location")
        ) {
          return $filters.push(
            `startsWith(fields/${key},'${encodeURIComponent(payload[key])}')`
          );
        }
        if (key === "keyword") {
          return $filters.push(
            `startsWith(fields/firstname,'${encodeString(
              payload[key]
            )}') or startsWith(fields/lastname,'${encodeString(payload[key])}')`
          );
        }

        $filters.push(`${key} eq '${payload[key]}'`);
      });
      payload = {
        $filter: $filters.join(" and "),
        $select:
          "fullname,department,division,officelocation,platform,team,emailaddress1,entityimage,jobtitle,mobilephone,json",
      };
    }
    return payload;
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    [API.keys.GET_PROFILES, generatePayload(), filter, keyName, isCustomSearch],
    API.queries.searchProfiles,
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: !!token,
    }
  );
}

export default useSearchManager;
