import styled from 'styled-components';

export const Dialog = styled.dialog`
    position: absolute;
    // padding-left: 40%;
    // padding-top: 5%;
    background-color: #fafafacc;
    backdrop-filter: blur(5px);
    border: none;
    width: 100%;
    height: 100%;
    z-index: 99999999
`;
