import { nextTick } from "process";
import type { QueryFunctionContext } from "react-query";
import { baseURLGraph, graphSPSiteID } from "../constants";

import API, { getDefaultHeaders } from "./axios";
import { getAllFilters } from "./queriesFilter";
import * as transformers from "./transformers";

const getExtraQuery = (payload: any) => {
  if (typeof payload === "string") {
    return `&${encodeURIComponent(payload)}`;
  }

  if (payload?.$filter?.length) {
    return `&$filter=${payload?.$filter}`;
  }

  //return `&$top=50`;
  return;
};

export const getNextDataSet = async (result: any, url: any) => {
  const apiResponse = {
    value: result?.data?.value,
    nextLink: result?.data?.["@odata.nextLink"],
  } as any;
  let index = 1;
  do {
    const pageData = await API.get({
      fullUrl: apiResponse?.nextLink,
      withGraphToken: true,
    });
    apiResponse.value = [...apiResponse.value, ...pageData?.data?.value];

    if (pageData?.data?.["@odata.nextLink"]) {
      apiResponse.nextLink = pageData?.data?.["@odata.nextLink"];
    } else {
      apiResponse.nextLink = "";
    }
    index++;
  } while (apiResponse?.nextLink?.length && index < 2);

  return apiResponse;
};

/**
 * Search top 200 profiles
 * @returns
 */
export const searchProfiles = async ({ queryKey }: QueryFunctionContext) => {
  try {
    const [_, payload, filters, keyName, showFullSearch] = queryKey;

    if (
      !showFullSearch &&
      (keyName as string)?.toString()?.toLowerCase() !== "firstname"
    ) {
      return {
        error: "",
        data: {
          value: [] as any,
          filters: {} as any,
        } as any,
        isRefetching: "",
        isLoading: false,
        isError: false,
      } as any;
    }

    let allFilters: any = filters as any;
    if (!allFilters?.department?.length) {
      allFilters = await getAllFilters();
    }

    const extraQuery = getExtraQuery(payload);

    let url =
      typeof payload === "string"
        ? `/sites/${graphSPSiteID}/lists/PB Contact/items?$expand=fields${
            extraQuery || ""
          }`
        : `/sites/${graphSPSiteID}/lists/PB Contact/items?expand=fields(select=contactid,department,emailaddress1,entityimage,fullname,firstname,division,json,officelocation,platform,team,jobtitle,lastname,mobilephone,middlename,COE_Public_Private,Vendor)${
            extraQuery || ""
          }`;

    if (
      !url?.includes("$top") &&
      extraQuery?.toString()?.length &&
      showFullSearch
    ) {
      url = `${url}&$top=6000`;
    }
    if (
      !url?.includes("$top") &&
      !extraQuery?.toString()?.length &&
      !showFullSearch
    ) {
      url = `${url}&$top=50`;
    }
    if (!url?.includes("$orderby") && !url?.includes("$top")) {
      url = `${url}&$top=6000`;
    }
    url = `${url}&$orderby=fields/lastname`;

    const result = await API.get({
      url,
      withGraphToken: true,
    });
    //const fullResult = await getNextDataSet(result, url);

    return transformers.searchProfilesFormatter(
      result?.data?.value?.map((profile: any) => profile?.fields),
      allFilters,
      payload
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Fetch data of individual profile by employee id
 * @param param0
 * @returns
 */
export const fetchProfile = async ({
  queryKey,
}: QueryFunctionContext): Promise<any> => {
  try {
    const [_, data]: any = queryKey;
    const payload = {
      $filter: data,
    };

    const result = await API.get({
      url: `/sites/${graphSPSiteID}/lists/PB Contact/items?expand=fields`,
      withGraphToken: true,
      params: payload,
    });
    return result.data.value.length > 0
      ? transformers.profileDetailsTransformer(result.data.value[0].fields)
      : {};
  } catch (error) {
    throw error;
  }
};

export const fetchProfilePicture = async ({
  queryKey,
}: QueryFunctionContext): Promise<any> => {
  try {
    const [_, data]: any = queryKey;
    const defaultHeaders: { Authorization?: string } = getDefaultHeaders({
      withGraphToken: true,
    });
    const headers = { ...defaultHeaders, responseType: "blob" };

    const response = await fetch(`${baseURLGraph}/users/${data}/photo/$value`, {
      method: "GET",
      headers,
    });
    if (response?.status === 200) {
      const blob = response?.blob();
      return blob;
    }
    return;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetch data of individual profile by employee id
 * @param param0
 * @returns
 */
export const searchByCustomFilter = async ({
  queryKey,
}: QueryFunctionContext): Promise<any> => {
  const [_, payload] = queryKey;
  const result = await API.get({
    url: `/sites/${graphSPSiteID}/lists/PB Contact/items?expand=fields&${payload}`,
    withGraphToken: true,
  });
  return result.data.value;
};

export const fetchBioUrl = async ({
  queryKey,
}: QueryFunctionContext): Promise<any> => {
  const [_, payload] = queryKey;
  const result = await API.get({
    url: `${payload}`,
    bioUrl: true,
  });
  return result.data.value;
};

const defaultExports = {
  fetchProfile,
  fetchProfilePicture,
  searchProfiles,
  searchByCustomFilter,
  fetchBioUrl,
};
export default defaultExports;
