import { Stack } from "react-bootstrap";
import styled from "styled-components";
import Img from "../Media";

export const Root = styled(Stack)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-height: 110px;
  p {
    margin: 0 0;
  }
`;

export const Media = styled(Img)`
  border-radius: 6px;
`;

export const CardInfo = styled.div`
  margin-left: 15px;
  width: 100%;

  .subtitle {
    text-transform: uppercase;
    font-size: 12px;
  }
  .title a {
    font-size: 14px;
    color: #2c90ce;
    font-weight: 600;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .profession {
    font-size: 12px;
  }
  .location,
  .email {
    font-size: 14px;
    span {
      font-size: 16px;
      margin-right: 5px;
      color: #2c90ce;
    }
  }
`;
