import { Accordion, Button } from "react-bootstrap";
import styled from "styled-components";

export const RootAccordian = styled(Accordion)`
  height: max-content;
`;

export const LinkItem = styled(Button)`
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  color: #2c90ce;
`;

export const SelectedLinkItem = styled(Button)`
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  color: #969ea9;
  justify-content: space-between;
  display: flex;
`;
