import styled from 'styled-components';
import Img from '../../../../components/Media';

export const Root = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 9ex 10px 9ex 0;
	/* margin: 30px 10px; */
	width: 100%;
	max-height: 110px;
	border-bottom: 1px solid #ccc;

	p {
		margin: 0 0;
	}

	@media screen and (max-width: 700px) {
		padding: 11ex 10px;
		max-height: 210px;
		height: max-content;
	}
`;

export const Media = styled(Img)`
	border-radius: 6px;
`;

export const CardInfo = styled.div`
	margin-left: 30px;
	width: 100%;

	.subtitle {
		text-transform: uppercase;
		font-size: 12px;
	}

	.title a {
		font-size: 16px;
		color: #2c90ce;
		font-weight: 600;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.profession {
		font-size: 12px;
		font-weight: bold;
	}
	.location,
	.email {
		font-size: 14px;
		span {
			font-size: 16px;
			margin-right: 5px;
			color: #2c90ce;
		}
	}
`;
