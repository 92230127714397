import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import {
  Container,
  Form,
  FormControl,
  InputGroup,
  Navbar,
  Stack,
} from "react-bootstrap";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LogoImage from "../../assets/wmc_logo.png";
import LogoWMCentral from "../../assets/wellingtonlogo.png";
import useQueryString from "../../hooks/useQueryString";
import useScreenSize from "../../hooks/useScreenSize";
import useAuthProvider from "../../hooks/useAuthProvider";
import SearchSuggestion from "../SearchSuggestion";

import NoImagePlaceholder from "../../assets/NoPicture.png";
import API from "../../api";
import { useQuery } from "react-query";

import * as Styled from "./customStyles";

function Header() {
  const { accounts } = useMsal();
  const token = useAuthProvider.useHandleLoginRedirect();
  const location = useLocation();
  const qs = useQueryString();
  const { isDesktopOrLaptop } = useScreenSize();
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

  const profilePictureQuery = useQuery(
    [API.keys.GET_PROFILE_PICTURE, accounts?.[0]?.username],
    API.queries.fetchProfilePicture,
    {
      enabled: !!accounts?.[0]?.username && !!token,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

  let srcURL = NoImagePlaceholder;
  if (profilePictureQuery?.data) {
    const urlCreator = window.URL || window.webkitURL;
    srcURL = urlCreator.createObjectURL(profilePictureQuery?.data);
  } else {
    srcURL = NoImagePlaceholder;
  }

  const onKeywordChange = (text: string) => {
    if (qs.get("showFullSearch")) {
      qs.delete("showFullSearch");
      navigate({ search: qs.toString() });
    }
    // qs.set("keyword", text);
    setSearchKeyword(text);
    // navigate({ search: qs.toString() });
    setShowSuggestions(true);
  };

  const toggleShowSuggestions = () => setShowSuggestions(!showSuggestions);

  const onShowFullSearch = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      qs.set("showFullSearch", "true");
      if (searchKeyword?.toString()?.trim()?.length) {
        qs.set("keyword", searchKeyword);
      }
      navigate({ pathname: "/", search: qs.toString() });
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    if (searchKeyword?.toString()?.trim()?.length === 0) {
      qs.delete("keyword");
    }
  }, [searchKeyword]);

  useEffect(() => {
    setSearchKeyword(qs.get("keyword") || "");
    // eslint-disable-next-line
  }, [token]);

  const openInNewTab = (link = "") => {
    window.open(link, "_blank");
  };

  return (
    <Styled.Nav bg="dark" variant="dark">
      <Container>
        <Stack direction="vertical">
          <Stack>
            {!isDesktopOrLaptop && (
              <a
                href={process.env.REACT_APP_WellingtonCentral}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Styled.NavbarTitle> Wellington Central</Styled.NavbarTitle>
              </a>
            )}
          </Stack>
          <Stack direction="horizontal">
            <Navbar.Brand
              style={{ minWidth: isDesktopOrLaptop ? "22.5%" : "max-content" }}
            >
              {isDesktopOrLaptop && (
                <a
                  href={process.env.REACT_APP_WellingtonCentral}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Styled.NavbarTitle> Wellington Central</Styled.NavbarTitle>
                </a>
              )}
              <Link to="/">
                <Styled.NavbarLeftLogo
                  src={LogoImage}
                  alt="WMC Logo"
                  width="45"
                  height="40"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />

            <Form
              onSubmit={(e) => e.preventDefault()}
              style={{ position: "relative", zIndex: 111, marginRight: "10px" }}
              onFocus={() => setShowSuggestions(true)}
            >
              <Styled.SearchBox
                onKeyDown={onShowFullSearch}
                className="search-box"
              >
                <FormControl
                  aria-label="Text input with checkbox"
                  type="text"
                  value={searchKeyword}
                  className="search-input"
                  placeholder="First name, last name or location"
                  onChange={(e) => {
                    onKeywordChange(e.target.value);
                  }}
                />
                {searchKeyword?.length ? (
                  <InputGroup.Text
                    className="search-box-close-icon"
                    onClick={() => {
                      setSearchKeyword("");
                      navigate({ pathname: location.pathname });
                    }}
                  >
                    <AiOutlineClose size={14} />
                  </InputGroup.Text>
                ) : null}
                <InputGroup.Text className="search-box-icon">
                  <AiOutlineSearch />
                </InputGroup.Text>
              </Styled.SearchBox>
              {showSuggestions && (
                <SearchSuggestion
                  onClick={toggleShowSuggestions}
                  searchKeyword={searchKeyword}
                />
              )}
            </Form>
            {showSuggestions && (
              <Styled.SuggestionBackdrop onClick={toggleShowSuggestions} />
            )}

            <Navbar.Collapse className="justify-content-end">
              <Link to={`/profile/${accounts?.[0]?.username}`}>
                <Styled.NavbarRightAvatar
                  src={srcURL}
                  alt="This"
                  width="40"
                  height="40"
                />
              </Link>
            </Navbar.Collapse>
          </Stack>
        </Stack>
      </Container>
    </Styled.Nav>
  );
}

export default Header;
