import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { graphLoginRequest, msalConfig } from "./authConfig";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//import { loginRequest } from "./authConfig";
import { getGraphToken } from "./helpers/helper.functions";

const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload?.account) {
    const account = event?.payload?.account;
    msalInstance.setActiveAccount(account);
  }
  if (
    event.eventType === EventType.LOGIN_FAILURE ||
    // event.eventType === EventType.SSO_SILENT_FAILURE ||
    event.eventType === EventType.ACQUIRE_TOKEN_FAILURE
  ) {
    localStorage.clear();
    msalInstance.acquireTokenRedirect(graphLoginRequest);
  }
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const token = getGraphToken();
    if (error?.response?.status === 401) {
      localStorage.clear();
      toast.error(`Session Expired! Redirecting to login page`, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        toastId: "sessionExpired1",
      });
      setTimeout(() => {
        localStorage.clear();
        msalInstance.acquireTokenRedirect(graphLoginRequest);
      }, 3000);
    } else if (!token) {
      msalInstance.acquireTokenRedirect(graphLoginRequest);
    }
    return error;
  }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
