import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import Toast from 'react-bootstrap/Toast';

import Layout from "./containers/Layout";
import "./App.css";
import Login from "./components/Login";
import useAuthProvider from "./hooks/useAuthProvider";

function App() {
  useAuthProvider.useHandleLoginRedirect();

  return (
    <>
      <AuthenticatedTemplate>
        <Layout showToast={true} />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login onLogin={() => useAuthProvider.useHandleLoginRedirect()}  />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
