const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito


export const msalConfig = {
	auth: {
		clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
		authority: process.env.REACT_APP_MSAL_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
		redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI as string,
	},
	cache: {
		cacheLocation: 'localStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: isIE || isEdge || isFirefox, // Set this to "true" if you are having issues on IE11 or Edge
	},
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
// export const loginRequest = {
// 	scopes: [`${process.env.REACT_APP_CRM_API_URI}/.default`],
// };

//For Graph Token
export const graphLoginRequest = {
	scopes : ["User.Read", "Sites.Read.All"]
  }

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
	graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me',
};
