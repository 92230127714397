import type { QueryFunctionContext } from "react-query";
import lodash from "lodash";

import API from "./axios";
import * as transformers from "./transformers";
import { graphSPSiteID } from "../constants";

/**
 * Get Department Filters
 * @returns
 */
export const getDepartmentFilters = async () => {
  try {
    const result = await API.get({
      url: `/sites/${graphSPSiteID}/lists/PB Department/items?expand=fields(select=Title)`,
      withGraphToken : true
    });
    return {
      department: lodash.map(result?.data?.value, (item: any) => ({
        value: item?.fields?.Title,
        isDisabled: false,
      })),
    };
  } catch (error) {
    return {
      department: [],
    };
  }
};

/**
 * Get Divisions Filters
 * @returns
 */
export const getDivisionsFilters = async () => {
  try {
    const result = await API.get({
      url: `/sites/${graphSPSiteID}/lists/PB Division/items?expand=fields(select=Title)`,
      withGraphToken : true
    });
    return {
      division: lodash.map(result?.data?.value, (item: any) => ({
        value: item?.fields?.Title,
        isDisabled: false,
      }))
    };
  } catch (error) {
    return {
      division: [],
    };
  }
};

/**
 * Get Platforms Filters
 * @returns
 */
export const getPlatformsFilters = async () => {
  try {
    const result = await API.get({
      //url: `cr1a5_platforms?$select=cr1a5_name`,
      url : `/sites/${graphSPSiteID}/lists/PB Platform/items?expand=fields(select=Title)`,
      withGraphToken : true
    });
    return {
      platform: lodash.map(result?.data?.value, (item: any) => ({
        value: item?.fields?.Title,
        isDisabled: false,
      }))
    };
  } catch (error) {
    return {
      platform: [],
    };
  }
};

/**
 * Get Teams Filters
 * @returns
 */
export const getTeamsFilters = async () => {
  try {
    const result = await API.get({
      url: `/sites/${graphSPSiteID}/lists/PB Team/items?expand=fields(select=Title)`,
      withGraphToken: true
    });
    return {
      team: lodash.map(result?.data?.value, (item: any) => ({
        value: item?.fields?.Title,
        isDisabled: false,
      }))
    };
  } catch (error) {
    return {
      team: [],
    };
  }
};

/**
 * Get Locations Filters
 * @returns
 */
export const getLocationsFilters = async () => {
  try {
    const result = await API.get({
      url: `/sites/${graphSPSiteID}/lists/PB Location/items?expand=fields(select=Title)`,
      withGraphToken : true
    });
    return {
      location: lodash.map(result?.data?.value, (item: any) => ({
        value: item?.fields?.Title,
        isDisabled: false,
      }))
    };
  } catch (error) {
    return {
      location: [],
    };
  }
};

export const getAllFilters = async () => {
  try {
    const response = await Promise.all([
      getDepartmentFilters(),
      getDivisionsFilters(),
      getPlatformsFilters(),
      getTeamsFilters(),
      getLocationsFilters(),
    ]);

    const allFilters = response.reduce((filters, filter) => {
      return {
        ...filter,
        ...filters,
      };
    });

    return allFilters;
  } catch (error) {
    return {
      department: [],
      division: [],
      location: [],
      platform: [],
      team: [],
    };
  }
};

const defaultExports = {
  getDepartmentFilters,
  getDivisionsFilters,
  getPlatformsFilters,
  getTeamsFilters,
  getLocationsFilters,
  getAllFilters,
};
export default defaultExports;
