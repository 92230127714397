const KEYS = {
  GET_PROFILES: "GET_PROFILES",
  GET_SEARCH_PROFILES_BY_FILTERS: "GET_SEARCH_PROFILES_BY_FILTERS",
  GET_MANAGER_DETAILS: "GET_MANAGER_DETAILS",
  GET_PROFILE_SUGGESTIONS: "GET_PROFILE_SUGGESTIONS",

  GET_PROFILE_DETAILS: "GET_PROFILE_DETAILS",
  GET_PROFILE_PICTURE: "GET_PROFILE_PICTURE",
  GET_PROFILE_REPORTS: "GET_PROFILE_REPORTS",
  GET_ASSISTANT_DETAILS: "GET_ASSISTANT_DETAILS",
  GET_BIO_URL: "GET_BIO_URL",
};

export default KEYS;
