import { useMemo, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BiX } from "react-icons/bi";

import useQueryString from "../../../../hooks/useQueryString";
import * as Styled from "./customStyles";

interface SidebarFilterProps {
  filters: SidebarFilters;
}

interface ItemSliceProps {
  items: string[];
  onClick: (itemName: string) => void;
  selectedValue: string | null;
}

function ItemSlicer({ items, onClick, selectedValue = "" }: ItemSliceProps) {
  const [showAll, setShowAll] = useState<boolean>(false);
  return items?.length ? (
    <>
      {items?.slice(0, showAll ? items.length : 10).map((item: any) =>
        item?.value === selectedValue ? (
          <Styled.SelectedLinkItem
            key={item?.value}
            onClick={() => onClick(item?.value)}
            type="button"
            variant="light"
          >
            <span>{item?.value}</span>
            <BiX size={20} style={{ marginTop: "2px" }} />
          </Styled.SelectedLinkItem>
        ) : (
          <Styled.LinkItem
            key={item?.value}
            onClick={() => onClick(item?.value)}
            type="button"
            variant="light"
            disabled={item?.isDisabled}
          >
            {item?.value}
          </Styled.LinkItem>
        )
      )}

      {items?.length > 10 && (
        <Styled.LinkItem
          onClick={() => setShowAll(!showAll)}
          type="button"
          variant="light"
        >
          {showAll ? "Show Less" : `Show All (${items.length})`}
        </Styled.LinkItem>
      )}
    </>
  ) : null;
}

function SidebarFilter({ filters }: SidebarFilterProps) {
  const qs = useQueryString();
  const navigate = useNavigate();

  const handleOnClick = (type: string, val: string) => {
    if (qs.get(type) === val) {
      qs.delete(type);
      navigate({ search: qs.toString() });
    } else {
      qs.set(type, val);
      navigate({ search: qs.toString() });
    }
  };

  const disableClearFilter = useMemo(
    () =>
      ![
        "keyword",
        "department",
        "officelocation",
        "team",
        "division",
        "platform",
        "Vendor",
      ].some((item) => qs.get(item)),
    [qs]
  );

  const filterCategory = (data: any) => {
    return data?.filter((item: any) => item?.isDisabled === false);
  };

  return (
    <Styled.RootAccordian defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          Platform ({filterCategory(filters?.platform)?.length})
        </Accordion.Header>
        <Accordion.Body>
          <ItemSlicer
            items={filters.platform}
            onClick={(val) => handleOnClick("platform", val)}
            selectedValue={qs.get("platform")}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          Division ({filterCategory(filters?.division)?.length})
        </Accordion.Header>
        <Accordion.Body>
          <ItemSlicer
            items={filters.division}
            onClick={(val) => handleOnClick("division", val)}
            selectedValue={qs.get("division")}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          Department ({filterCategory(filters?.department)?.length})
        </Accordion.Header>
        <Accordion.Body>
          <ItemSlicer
            items={filters.department}
            onClick={(val) => handleOnClick("department", val)}
            selectedValue={qs.get("department")}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          Team ({filterCategory(filters?.team)?.length})
        </Accordion.Header>
        <Accordion.Body>
          <ItemSlicer
            items={filters.team}
            onClick={(val) => handleOnClick("team", val)}
            selectedValue={qs.get("team")}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          Location ({filterCategory(filters?.location)?.length})
        </Accordion.Header>
        <Accordion.Body>
          <ItemSlicer
            items={filters.location}
            onClick={(val) => handleOnClick("officelocation", val)}
            selectedValue={qs.get("officelocation")}
          />
        </Accordion.Body>
      </Accordion.Item>

      <Button
        type="button"
        className="w-100 p-2 mt-4"
        variant={!disableClearFilter ? "outline-primary" : "light"}
        disabled={disableClearFilter}
        onClick={() => navigate({ pathname: "/" })}
      >
        Clear All Filters
      </Button>
    </Styled.RootAccordian>
  );
}

export default SidebarFilter;
