import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import NoImagePlaceholder from "../../assets/download.png";
import { baseURLImage } from "../../constants/index";
import useScreenSize from "../../hooks/useScreenSize";
import * as Styled from "./customStyles";


interface ImgProps {
  userEmail: string;
  enableZoom: boolean;
  [x: string]: any;
}

function Img({ userEmail, enableZoom = false, width, height, ...rest }: ImgProps) {
  const [src, setSrc] = useState<any>(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const { isDesktopOrLaptop } = useScreenSize();

  const loadImage = () => {
    fetch(`${baseURLImage}/users/${userEmail}/photo/$value`, {
      headers: {
        responseType: "blob",
        Authorization: `Bearer ${process.env.REACT_APP_GRAPH_TOKEN}`,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          throw new Error("No image found");
        }
        return response.blob().then(function (myBlob) {
          var objectURL = URL.createObjectURL(myBlob);
          setSrc(objectURL);
        });
      })
      .catch(function () {
        setSrc(null);
      });
  };

  const openImageModal = () => {
    setIsImageModalOpen(!isImageModalOpen);  
  }

  useEffect(() => {
    setSrc(null);
  }, []);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(src);
    };
  }, [src]);

  return enableZoom ? (
    <>
      <LazyLoadImage
        src={src || NoImagePlaceholder}
        width={width}
        height={height}
        {...rest}
        onError={() => setSrc(null)}
        onClick={() => openImageModal()}
      />
      <Styled.Dialog
        style={{position: 'fixed', top : '0'}}
        open={isImageModalOpen}
        onClick={() => openImageModal()}
      >
        <LazyLoadImage
          src={src || NoImagePlaceholder}
          width={isDesktopOrLaptop ? '500': '350'}
          height={isDesktopOrLaptop ? '500': '350'}
          style={isDesktopOrLaptop ? {position: 'fixed', top: '20%', left: '40%'} : {position: 'fixed', top: '40%', left: '5%'}}
            {...rest}
          onError={() => setSrc(null)}
        />
      </Styled.Dialog>
    </>
  ) : (
    <LazyLoadImage
      src={src || NoImagePlaceholder}
      width={width}
      height={height}
    {...rest}
      onError={() => setSrc(null)}
    />
  );
}

export default Img;
