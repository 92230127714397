import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { baseBioLinkUrl, baseURLGraph } from "../constants/index";
import queries from "./queries";
import queryKeys from "./queryKeys";
import { getGraphToken, getToken } from "../helpers/helper.functions";

export const getDefaultHeaders = ({
  withGraphToken = false,
  bioUrl = false,
}) => {
  return withGraphToken || bioUrl
    ? {
        Authorization: getGraphToken() || "",
      }
    : {
        Authorization: getToken() || "",
      };
};

const getBaseURL = ({ withGraphToken = false, bioUrl = false }) => {
  if (bioUrl) {
    return baseBioLinkUrl;
  }
  if (withGraphToken) {
    return baseURLGraph;
  }
  return baseURLGraph;
};

interface AxiosProps extends AxiosRequestConfig {
  method?: "get" | "post" | "delete" | "patch" | "put";
  withoutToken?: boolean;
  withGraphToken?: boolean;
  bioUrl?: boolean;
  fullUrl?: string;
}

const Axios = ({
  method = "get",
  data,
  params,
  headers,
  url,
  withoutToken,
  withGraphToken,
  bioUrl,
  fullUrl,
  ...rest
}: AxiosProps): AxiosPromise => {
  const cancelTokenRequest = new AbortController();
  const defaultHeaders: { Authorization?: string } =
    getDefaultHeaders({withGraphToken, bioUrl});
  const _headers = { ...defaultHeaders, ...headers };

  if (withoutToken) delete _headers.Authorization; // If not include token

  //const imageReq: any = withGraphToken ? { responseType: "blob" } : {};

  const imageReq: any = { responseType: "blob" };

  axios.interceptors.request.use((request) => {
    if (!request?.headers?.Authorization && request?.headers) {
      const defaultHeaders: { Authorization?: string } =
        getDefaultHeaders({withGraphToken, bioUrl});
      request.headers["Authorization"] = getDefaultHeaders(
        {withGraphToken: request?.baseURL?.includes("graph"), bioUrl}
      )?.Authorization as string;
    }
    return request;
  });

  if (!_headers?.Authorization && !getToken()) {
    cancelTokenRequest.abort();
  }

  return axios.request(fullUrl?.toString()?.trim()?.length ? {
    headers: _headers,
    data,
    params,
    url: fullUrl,
    method,
    signal: cancelTokenRequest?.signal,
  } : {
    baseURL: rest.baseURL || getBaseURL({ withGraphToken, bioUrl }),
    headers: _headers,
    data,
    params,
    url,
    method,
    signal: cancelTokenRequest?.signal,
    //...imageReq,
  });
};

type CommonRequestProps = {
  url?: string;
  params?: any;
  withoutToken?: boolean;
  withGraphToken?: boolean;
  data?: any;
  headers?: any;
  bioUrl?: boolean;
  fullUrl?: string;
};

const get = ({
  params,
  withoutToken,
  withGraphToken,
  bioUrl,
  url,
  data,
  headers,
  fullUrl,
}: CommonRequestProps): AxiosPromise =>
  Axios({
    method: "get",
    url,
    params,
    withoutToken,
    data,
    headers,
    withGraphToken,
    bioUrl,
    fullUrl
  });

const post = ({
  params,
  withoutToken,
  url,
  data,
  bioUrl,
}: CommonRequestProps): AxiosPromise =>
  Axios({ method: "post", url, params, withoutToken, data, bioUrl });

const put = ({
  params,
  withoutToken,
  url,
  data,
  bioUrl,
}: CommonRequestProps): AxiosPromise =>
  Axios({ method: "put", url, params, withoutToken, data, bioUrl });

const defaultExpors = { get, post, put, Axios, queries, queryKeys };
export default defaultExpors;
