export const setToken = (token: string) => {
  localStorage.setItem("token", token);
};
export const getToken = () => {
  return (localStorage?.getItem("token")) ?? '';
};

export const setGraphToken = (token: string) => {
  localStorage.setItem("tokenGraph", token);
};
export const getGraphToken = () => {
  return (localStorage?.getItem("tokenGraph")) ?? '';
};

export const crmToken = {
  setToken: (token: string) => {
    localStorage.setItem("crm-token", token);
  },
  getToken: () => {
    return localStorage.getItem("crm-token");
  },
};
