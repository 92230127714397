import React, { useEffect, useState } from "react";
import { Container, Spinner, Stack } from "react-bootstrap";
import { BsFilterSquare } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";

import SearchItem from "./components/SearchItem";
import SidebarFilter from "./components/SidebarFilter";
import SidebarFilterModal from "./components/SidebarFilterModal";
import useSearchManager from "../../hooks/useSearchManager";
import useScreenSize from "../../hooks/useScreenSize";
import useAuthProvider from "../../hooks/useAuthProvider";
import * as transformers from "../../api/transformers";

import * as Styled from "./customStyles";
import useQueryString from "../../hooks/useQueryString";
import useSearchFilters from "../../hooks/useFilters";
import _ from "lodash";

function Home() {
  const qs = useQueryString();
  const isCustomSearch =
    !!qs.get("showFullSearch")?.length ||
    !!qs.get("platform")?.length ||
    !!qs.get("division")?.length ||
    !!qs.get("team")?.length ||
    !!qs.get("location")?.length ||
    !!qs.get("Vendor")?.length ||
    !!qs.get("department")?.length;

  const allFilters = useSearchFilters();
  const resultLastName: any = useSearchManager("lastname");
  // const resultLastName: any =  {
  //   error: '',
  //   data: {value: []}
  // }
  const resultFirstName: any = useSearchManager("firstname");
  const navigate = useNavigate();
  const token = useAuthProvider.useHandleLoginRedirect();
  const { isDesktopOrLaptop } = useScreenSize();
  const searchError: any = resultFirstName?.error || resultLastName?.error;
  const [showFilters, setShowFilters] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const emailAddress =
    searchParams?.get("email") || searchParams?.get("emailAddress") || "";

  useEffect(() => {
    if (emailAddress?.toString()?.trim()?.length) {
      navigate(`/profile/${emailAddress}`);
    }
  }, [emailAddress]);

  /*   
  useEffect(() => {
    if (
      searchError?.code === "ERR_BAD_REQUEST" &&
      searchError?.response?.status === 401 && 
      token
    ) {
      localStorage?.clear();
      navigate({pathname: '/' });
    }  
  }, [searchError])
 */

  if (resultFirstName?.isLoading)
    return (
      <Stack
        style={{ width: "100%" }}
        direction="horizontal"
        className="justify-content-center py-2"
      >
        <Spinner animation="border" />
      </Stack>
    );

  if (resultFirstName?.isError || resultLastName?.isError) {
    return (
      <Stack
        className="p-2 justify-content-center"
        style={{ width: "100%", height: "60vh" }}
      >
        <p style={{ textAlign: "center" }}>Something went wrong</p>
      </Stack>
    );
  }

  const isRefetching =
    resultFirstName?.isRefetching || resultLastName?.isRefetching;
  const isLoading = resultFirstName?.isLoading || resultLastName?.isLoading;

  let data = {
    filters: {} as any,
    value: [] as any,
  } as any;

  if (
    resultFirstName?.data?.value?.length ||
    resultLastName?.data?.value?.length
  ) {
    data = {
      filters: {
        ...(resultFirstName?.data?.filters || {}),
        ...(resultLastName?.data?.filters || {}),
      } as any,
      value: _.uniqBy(
        [
          ...(resultFirstName?.data?.value || []),
          ...(resultLastName?.data?.value || []),
        ] as any,
        "@odata.etag"
      ) as any,
    };
  }

  data = transformers.searchProfilesFormatter(
    data.value,
    allFilters,
    resultFirstName?.payload || resultLastName?.payload,
    isCustomSearch
  );

  return (
    <Container>
      <Stack direction="horizontal" className="align-items-start" gap={5}>
        <div
          className="mt-3 d-none d-md-block "
          style={{
            minWidth: "20%",
            height: "100vh",
          }}
        >
          {data?.filters && (
            <>
              <SidebarFilter filters={data?.filters} />
              <SidebarFilterModal
                show={showFilters}
                filters={data?.filters}
                closeModal={() => setShowFilters(!showFilters)}
              />
            </>
          )}
        </div>

        <div style={{ minWidth: isDesktopOrLaptop ? "80%" : "80%" }}>
          {(resultFirstName?.isRefetching || resultLastName?.isRefetching) && (
            <Stack
              style={{ width: "100%" }}
              direction="horizontal"
              className="w-100 h-100 justify-content-center py-2"
            >
              <Spinner animation="border" />
            </Stack>
          )}

          <Styled.FiltersBar onClick={() => setShowFilters(true)}>
            <BsFilterSquare />
            Filters
          </Styled.FiltersBar>

          {data?.value.map((item: any) => (
            <SearchItem key={item.emailaddress1} item={item} />
          ))}

          {data?.value?.length <= 0 && !isLoading && !isRefetching && (
            <Stack
              className="p-2 justify-content-center"
              style={{ width: "100%", height: "60vh" }}
            >
              <p style={{ textAlign: "center" }}>No results found</p>
            </Stack>
          )}
        </div>
      </Stack>
    </Container>
  );
}

export default Home;
