import React, { useEffect, useState } from "react";
import { getAllFilters } from "../api/queriesFilter";

export const useSearchFilters = () => {
  const [filters, setFilters] = useState<any>();

  useEffect(() => {

    if (
      filters?.department?.length &&
      filters?.division?.length &&
      filters?.location?.length &&
      filters?.platform?.length &&
      filters?.team?.length
    ) {
      return;
    }

    const fetchFilters = () => {
      getAllFilters()
        .then((res: any) => {
          setFilters(res);
        })
        .catch((error: any) => {
          setFilters("");
        });
    };

    fetchFilters();
  }, []);

  return filters;
};

export default useSearchFilters;
