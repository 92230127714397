import _ from "lodash";
import { UserProfile } from "./types";

interface SearchProfileData {
  filters: SidebarFilters;
  value: any;
  payload?: any;
  allFilters?: any;
}

export const deepCopy = (data = {}) => {
  return JSON.parse(JSON.stringify(data));
};

export const searchProfilesFormatter = (
  data: any,
  filters?: any,
  payload?: any,
  showFullSearch: boolean = false
) => {
  data = data?.map((profile: any) => {
    if (profile?.fullname?.length) {
      return profile;
    }
    let fullName = profile?.firstname || "";
    fullName +=
      fullName?.length && profile?.middlename?.length
        ? ` ${profile?.middlename}`
        : profile?.middlename?.length
        ? profile?.middlename
        : "";
    fullName +=
      fullName?.length && profile?.lastname?.length
        ? ` ${profile?.lastname}`
        : profile?.lastname?.length
        ? profile?.lastname
        : "";
    profile.fullname = fullName;
    return profile;
  });

  const updatedData: SearchProfileData = {
    filters: deepCopy(filters) || {
      department: [],
      division: [],
      location: [],
      platform: [],
      team: [],
    },
    value: _.sortBy(data, "lastname")?.filter(
      (item) => item?.emailaddress1?.toString()?.trim()?.length
    ),
    payload: payload,
  };

  data = data?.map((item: any) => {
    if (typeof item?.json === "string") {
      item.json = JSON?.parse(item?.json);
    }
    return item;
  });

  const customFilters = {
    department: _.uniq(_.map(data, "department") || []),
    division: _.uniq(_.map(data, "division") || []),
    location: _.uniq(_.map(data, "officelocation") || []),
    platform: _.uniq(_.map(data, "platform") || []),
    team: _.uniq(_.map(data, "team") || []),
  };

  if (typeof payload === "string" || payload?.$filter || showFullSearch) {
    updatedData.filters.department = deepCopy(
      updatedData?.filters
    )?.department?.map((item: any) => {
      if (!customFilters?.department?.includes(item?.value)) {
        item.isDisabled = true;
      }
      return item;
    });

    updatedData.filters.division = deepCopy(
      updatedData?.filters
    )?.division?.map((item: any) => {
      if (!customFilters?.division?.includes(item?.value)) {
        item.isDisabled = true;
      }
      return item;
    });

    updatedData.filters.location = deepCopy(
      updatedData?.filters
    )?.location?.map((item: any) => {
      if (!customFilters?.location?.includes(item?.value)) {
        item.isDisabled = true;
      }
      return item;
    });

    updatedData.filters.platform = deepCopy(
      updatedData?.filters
    )?.platform?.map((item: any) => {
      if (!customFilters?.platform?.includes(item?.value)) {
        item.isDisabled = true;
      }
      return item;
    });

    updatedData.filters.team = deepCopy(updatedData?.filters)?.team?.map(
      (item: any) => {
        if (!customFilters?.team?.includes(item?.value)) {
          item.isDisabled = true;
        }
        return item;
      }
    );
  } else {
    updatedData.filters = deepCopy(filters);
  }

  // Object.keys(updatedData.filters).forEach((key) => {
  //   updatedData.filters[key] = _.uniq(updatedData.filters[key]);
  // });
  return updatedData;
};

export const profileDetailsTransformer = (data: UserProfile) => {
  let updatedData = data;
  if (!data.json) {
    updatedData = {
      ...data,
      json: JSON.stringify({
        Work_Email: data.emailaddress1,
        Last_Name: data.lastname,
        First_Name: data.firstname,
        Middle_Intial: (data?.middlename || "").slice(0, 1),
        Job_Title: data.jobtitle,
        Location: data.officelocation,
        Employee_Summary: data.description,
        System_Intials: data.systeminitials,
        Officership: data.officership,
        Pronouns: data.pronouns,
        Hire_Date: data.anniversary,
        Platform: data.platform,
        Work_History: [],
        Floor: data.floor?.toString() || "Not Provided",
        Wellington_Work_History: [],
        Languages: [],
        Education: [],
        Mobile_Phone: data.mobilephone || "Not Provided",
        Work_Phone: data.mobilephone || "Not Provided",
        extension: data.extension,
        COE_Public_Private: data.COE_Public_Private,
      }),
    };
  }

  return updatedData;
};
