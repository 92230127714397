import React, { useEffect, useState } from "react";
import { useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";
import {
  getToken,
  setToken,
  getGraphToken,
  setGraphToken,
} from "../helpers/helper.functions";
import {
  InteractionRequiredAuthError,
  InteractionType,
} from "@azure/msal-browser";
import { graphLoginRequest } from "../authConfig";
import NoImagePlaceholder from "../assets/NoPicture.png";

const useLogin = () => {
  const { instance } = useMsal();

  instance
    .loginPopup(graphLoginRequest)
    .then((accessTokenResponse: { accessToken: any }) => {
      let accessToken = accessTokenResponse.accessToken;
      setGraphToken(accessToken as string);
    })
    .catch((e: Error) => {
      console.error(e);
    });
};

const useHandleLogin = () => {
  const { instance, accounts } = useMsal();
  const savedGraphToken = getGraphToken();
  const account = useAccount(accounts[0] || {});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [graphAuthToken, setGraphAuthToken] = useState(savedGraphToken || "");

  useEffect(() => {
    if (!account) {
      instance
        .loginPopup(graphLoginRequest)
        .then((accessTokenResponse: { accessToken: any }) => {
          let accessToken = accessTokenResponse.accessToken;
          setGraphAuthToken(accessToken);
          setGraphToken(accessToken as string);
        })
        .catch((e: Error) => {
          console.error(e);
        });

      return;
    }

    const fetchGraphToken = () => {
      const requestGraph = {
        scopes: ["User.Read", "Sites.Read.All"],
        account,
      };
      instance
        .acquireTokenSilent(requestGraph)
        .then((response) => {
          if (response) {
            setGraphToken(response?.accessToken);
            setGraphAuthToken(response.accessToken);
          }
        })
        .catch((error) => {
          if (
            InteractionRequiredAuthError?.isInteractionRequiredError(
              error?.errorCode
            ) ||
            error instanceof InteractionRequiredAuthError
          ) {
            instance?.acquireTokenPopup(requestGraph);
          }
        });
    };

    fetchGraphToken();
  }, [account, instance]);

  return graphAuthToken;
};

const useHandleLoginRedirect = () => {
  const { instance, accounts } = useMsal();
  const savedGraphToken = getGraphToken();
  const account = useAccount(accounts[0] || {});
  const [graphAuthToken, setGraphAuthToken] = useState(savedGraphToken || "");
  const { login, error } = useMsalAuthentication(
    InteractionType.Redirect,
    graphLoginRequest
  );

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, graphLoginRequest);
    }
  }, [error]);

  useEffect(() => {
    if (!account) {
      login(InteractionType.Redirect, graphLoginRequest);
      return;
    }

    const fetchGraphToken = () => {
      const requestGraph = {
        scopes: ["User.Read", "Sites.Read.All"],
        account,
      };

      instance
        .acquireTokenSilent(requestGraph)
        .then((response) => {
          if (response) {
            setGraphToken(response?.accessToken);
            setGraphAuthToken(response.accessToken);
          }
        })
        .catch((error) => {
          console.log('error: ', error)
          if (
            InteractionRequiredAuthError?.isInteractionRequiredError(
              error?.errorCode
            ) ||
            error instanceof InteractionRequiredAuthError
          ) {
            instance?.acquireTokenRedirect(requestGraph);
          }
        });
    };

    fetchGraphToken();
  }, [account, instance]);

  return graphAuthToken;
};

const authProvider = {
  useHandleLogin,
  useHandleLoginRedirect,
  useLogin,
};
export default authProvider;
