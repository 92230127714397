import { useMediaQuery } from 'react-responsive';

function useScreenSize() {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-width: 1000px)',
	});
	return { isDesktopOrLaptop };
}

export default useScreenSize;
