import React from "react";
import { Spinner, Stack } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";

import API from "../../api";
import { UserProfile } from "../../api/types";
import useQueryString from "../../hooks/useQueryString";
import ProfileCardHorizontal from "../ProfileCardHorizontal";
import * as Styled from "./customStyles";
import useAuthProvider from "../../hooks/useAuthProvider";
import useSearchFilters from "../../hooks/useFilters";

const encodeString = (value: string = "") => {
  return encodeURIComponent(value.replace(/'/g, "''"));
};

function SearchSuggestion({ searchKeyword = "", ...rest }) {
  const qs = useQueryString();

  const keyword = searchKeyword ?? qs.get("keyword");
  const token = useAuthProvider.useHandleLoginRedirect();
  const navigate = useNavigate();
  const filter = useSearchFilters();

  const payloadFirstName = {
    $filter: [
      `startsWith(fields/firstname,'${encodeString(keyword)}')`,
    ],
  };
  const payloadLastName = {
    $filter: [
      `startsWith(fields/lastname,'${encodeString(keyword)}')`,
    ],
  };

  const resultFirstName = useQuery(
    [API.keys.GET_PROFILE_SUGGESTIONS, payloadFirstName, filter, 'firstname', false],
    API.queries.searchProfiles,
    {
      enabled: !!keyword && keyword?.length >= 3 && !!token,
      keepPreviousData: true,
      staleTime: 1200000,
    }
  );

  const resultLastName = useQuery(
    [API.keys.GET_PROFILE_SUGGESTIONS, payloadLastName, filter, 'lastname', true],
    API.queries.searchProfiles,
    {
      enabled: !!keyword && keyword?.length >= 3 && !!token,
      keepPreviousData: true,
      staleTime: 1200000,
    }
  );


  let data = {
    value: [] as any,
  };

  if (resultFirstName?.data?.value?.length || resultLastName?.data?.value?.length) {
    data.value = [...(resultFirstName?.data?.value || []), ...(resultLastName?.data?.value || [])]
  }

  const locations = _.uniq([
    ..._.map(resultFirstName?.data?.filters?.location, "value"),
    ..._.map(resultLastName?.data?.filters?.location, "value"),
  ]);
  const locationData = searchKeyword?.toString()?.length ? locations?.filter((location) =>
    location?.toLowerCase()?.includes(keyword?.toLowerCase())
  ) : [];

  if (
    resultFirstName?.isLoading ||
    resultFirstName?.isError ||
    resultLastName?.isLoading ||
    resultLastName?.isError
  ) {
    return (
      <Styled.Root>
        <Stack className="w-100 justify-content-center align-items-center py-2">
          {(resultFirstName?.isError || resultLastName?.isError) ? <p>No results found</p> : <Spinner animation="border" />}
        </Stack>
      </Styled.Root>
    );
  }

  if (!data?.value?.length && qs.get("keyword")?.toString()?.trim()?.length) {
    return (
      <Styled.Root>
        <Stack className="w-100 justify-content-center align-items-center py-2">
          <p>No results found</p>
        </Stack>
      </Styled.Root>
    );
  }

  const generateItemData = (data: UserProfile) => {
    return {
      Work_Email: data.emailaddress1,
      Last_Name: data.lastname,
      First_Name: data.firstname,
      Job_Title: data.jobtitle,
      Location: data.officelocation,
      imageURL: data.entityimage,
    };
  };

  const openFullSearch = (item: string) => {
    qs.set("showFullSearch", "true");
    qs.set("officelocation", item);
    navigate({ pathname: "/", search: qs.toString() });
  };

  return (
    <Styled.Root {...rest}>
      <Styled.Group>
        {keyword?.toString()?.trim()?.length && data?.value?.length ? (
          <Styled.SearchSuggestionsGroupHeader>
            Profiles
          </Styled.SearchSuggestionsGroupHeader>
        ) : null}
        {keyword?.toString()?.trim()?.length && !(resultFirstName?.isLoading || resultLastName?.isLoading)
          ? data?.value?.map((item: any) => {
              let parsedData = item.json;

              if (!parsedData) {
                parsedData = generateItemData(item);
              } else if (typeof item?.json === 'string') {
                parsedData = JSON.parse(item.json);
              } else {
                parsedData = item.json;
              }

              return (
                <Styled.SearchItem key={item.contactid}>
                  <Link
                    to={`/profile/${parsedData?.Work_Email}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <ProfileCardHorizontal
                      data={parsedData}
                      altProfileImage={item?.entityimage}
                      keyword={keyword}
                      small
                    />
                  </Link>
                </Styled.SearchItem>
              );
            })
          : null}
      </Styled.Group>

      <Styled.Group>
        {keyword?.toString()?.trim()?.length && locationData?.length ? (
          <Styled.SearchSuggestionsGroupHeader>
            Locations
          </Styled.SearchSuggestionsGroupHeader>
        ) : null}

        <Styled.ChipGroup>
          {keyword?.toString()?.trim()?.length && locationData?.length
            ? locationData?.map((item) => (
                <Styled.Chip key={item} onClick={() => openFullSearch(item)}>
                  {item}
                </Styled.Chip>
              ))
            : null}
        </Styled.ChipGroup>
      </Styled.Group>
    </Styled.Root>
  );
}

export default SearchSuggestion;
