import React from 'react';
import { Button, Stack } from 'react-bootstrap';
import styled from 'styled-components';

import LogoImage from '../assets/wmc_logo.png';

const Root = styled(Stack)`
	height: 100vh;
	width: 100vw;
	background: #eee;

	button {
		padding: 10px 20px;
		border-radius: 6px;
		outline: none;
		box-shadow: 0 4px 5px #fbfeff;
		margin-top: 10px;
	}
`;

const InnerLogin = styled.div`
	width: 350px;
	min-height: 300px;
	height: max-content;
	border: 1px solid #ccc;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: #fff;
	padding: 20px;
	border-radius: 6px;
`;

const Logo = styled.img`
	width: 60px;
	height: 60px;
	margin-bottom: 30px;
`;

const ErrorM = styled.p`
	color: red;
	width: 100%;
	word-wrap: break-word;
	margin-top: 10px;
	font-size: 10px;
`;

interface LoginProps {
	onLogin: () => void;
	error?: Error | null;
}

function Login({ onLogin, error }: LoginProps) {
	return (
		<Root className='justify-content-center align-items-center'>
			<InnerLogin>
				<Logo src={LogoImage} alt='Picturebook' />
				<h5>Please login</h5>
				<Button onClick={onLogin}>Click here to login</Button>
				{error && <ErrorM>{error.message || 'Something went wrong'}</ErrorM>}
			</InnerLogin>
		</Root>
	);
}

export default Login;
