import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../components/Header";

import Home from "./Home";
const ProfileDetails = lazy(() => import("./ProfileDetails"));

function Layout({ showToast = false }) {
  return (
    <div>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route
          path="/profile/:id"
          element={
            <Suspense fallback="Loading...">
              <ProfileDetails />
            </Suspense>
          }
        />
        <Route
          path="/profile"
          element={
            <Suspense fallback="Loading...">
              <ProfileDetails />
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
}

export default Layout;
