import { Button } from "react-bootstrap";
import styled from "styled-components";

export const FiltersBar = styled(Button)`
  display: none;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 10px;
  }

  @media screen and (max-width: 700px) {
    display: flex;
  }
`;
