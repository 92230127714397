import styled from "styled-components";

export const Root = styled.div`
  position: absolute;
  z-index: 111;
  background-color: #fff;
  width: 100%;
  padding: 0 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 5px;
  border-radius: 6px;
`;

export const Group = styled.div`
  height: max-content;
`;

export const SearchItem = styled.div`
  padding: 5px 20px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const SearchSuggestionsGroupHeader = styled.p`
  position: sticky;
  top: 0;
  padding: 10px 20px 5px 20px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  margin: 0 0;
  font-weight: bold;
  background-color: #fff;
`;

export const ChipGroup = styled.div`
  padding: 10px 18px;
`;

export const Chip = styled.p`
  border-radius: 30px;
  margin-bottom: 5px;
  margin-right: 10px;
  border: 2px solid #e7f1ff;
  background-color: #f1f3f7;
  padding: 2px 15px;
  font-size: 12px;
  width: max-content;
  display: inline-flex;
  transition: all 0.27s linear;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;
