import styled from "styled-components";
import { Image, InputGroup, Navbar } from "react-bootstrap";
import { Label } from "@fluentui/react";

export const Nav = styled(Navbar)`
  background-color: #17224e !important;

  .search-box {
    .search-input {
      border: none !important;
      box-shadow: none !important;
    }
    .input-group-text {
      border: none !important;
    }
    .search-box-icon {
      background-color: #fff;
      border-left: 1px solid #ced4da !important;
      box-shadow: none;
      outline: none;
    }
    .search-box-close-icon {
      background-color: #fff;
      border: none;
      border-left: 0px !important;
      box-shadow: none;
      outline: none;
      cursor: pointer;
    }
  }

  .clear-btn {
    background-color: transparent;
    border: none;
    font-weight: bold;
    margin-left: 10px;
  }
`;

export const NavbarLeftDomainLogo = styled(Image)`
  margin-right: 10px;
`;

export const NavbarLeftLogo = styled(Image)``;

export const NavbarRightAvatar = styled(Image)`
  border: 2px solid #eee;
`;

export const NavbarTitle = styled(Label)`
  color: White;
  display: inline-block;
  margin-right: 6em;
  font-weight: 600;
  font-family: SegoeUI-SemiBold-final, Segoe UI Semibold, SegoeUI-Regular-final,
    Segoe UI, "Segoe UI Web (West European)", Segoe, -apple-system,
    BlinkMacSystemFont, Roboto, Helvetica Neue, Tahoma, Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  cursor: pointer;

  @media (max-width: 700px) {
    margin-right: 0;
    text-align: center;
    width: 100%;
  }
  :hover {
    color: rgb(0, 124, 186);
  }
`;

export const SearchBox = styled(InputGroup)`
  width: 350px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const SuggestionBackdrop = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.3);
`;
